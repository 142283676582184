<template>
	<div class="card">
		<div class="card-header bg-white border-bottom-str">
			<h6 class="card-title font-weight-semibold">Tambah Kajian Resiko Jatuh</h6>
		</div>
		<validation-observer ref="VForm">
			<b-form @submit.prevent="doSubmit">
				<div class="card-body">
					<div class="col-md-4">
						<div class="form-group">
							<label for="kenyamananNyeri">Tanggal <span class="text-danger">*</span></label>
							<div class="input-group mb-3">
								<datepicker :disabledDates="disabledDates" input-class="form-control transparent h-38"
									placeholder="Tanggal" class="my-datepicker" calendar-class="my-datepicker_calendar"
									v-model="rowData.arankprj_date">
								</datepicker>

								<vue-timepicker manual-input format="HH:mm" placeholder="Jam" input-class="form-control h-38"
									v-model="rowData.arankprj_jam">
								</vue-timepicker>
								<div class="input-group-append calendar-group">
									<span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
								</div>
							</div>
							<VValidate :name="'Tanggal'" v-model="rowData.arankprj_date" :rules="{required : 1}" class="d-block" />
							<VValidate :name="'Jam'" v-model="rowData.arankprj_jam" :rules="{required: 1}" />
						</div>
					</div>
				</div>
				<template
					v-if="isParent.rowReg.aranr_kajian_awal_type == 'NICU' || isParent.rowReg.aranr_kajian_awal_type == 'PER'">
					<div class="card mb-0">
						<div class="card-header bg-info">
							<h5 class="card-title font-weight-semibold">Pengkajian Risiko Jatuh Neonatus</h5>
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col-md-6">
									<div class="card">
										<div class="card-header bg_head_panel">
											<h6 class="card-title font-weight-semibold">INTERVENSI</h6>
										</div>
										<div class="card-body">
											<div class="form-group mt-3">
												<div>
													<b-form-checkbox-group class="checkbox-inline" v-model="rowData.arankprjd_neo_intervensi"
														:options="mNeoIntervensi"></b-form-checkbox-group>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="card">
										<div class="card-header bg_head_panel">
											<h6 class="card-title font-weight-semibold">EDUKASI YANG DIBERIKAN</h6>
										</div>
										<div class="card-body">
											<div class="form-group mt-3">
												<div class="">
													<b-form-checkbox-group class="checkbox-inline" v-model="rowData.arankprjd_neo_edukasi"
														:options="mNeoEdukasi"></b-form-checkbox-group>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="card">
										<div class="card-header bg_head_panel">
											<h6 class="card-title font-weight-semibold">SASARAN EDUKASI</h6>
										</div>
										<div class="card-body">
											<div class="form-group mt-3">
												<div class="">
													<b-form-checkbox-group class="checkbox-inline" v-model="rowData.arankprjd_neo_sasaran"
														:options="mNeoSasaran"></b-form-checkbox-group>
												</div>
												<div v-if="(rowData.arankprjd_neo_sasaran||[]).indexOf('Lainnya') != -1">
													<b-form-input v-model="rowData.arankprjd_neo_sasaran_lainnya" type="text"
														class="form-control" />

													<VValidate :name="'Lainnya'" v-model="rowData.arankprjd_neo_sasaran_lainnya"
														:rules="{required : 1, min: 3, max:128}" />
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="card">
										<div class="card-header bg_head_panel">
											<h6 class="card-title font-weight-semibold">EVALUASI</h6>
										</div>
										<div class="card-body">
											<div class="form-group mt-3">
												<div class="">
													<b-form-checkbox-group class="checkbox-inline" v-model="rowData.arankprjd_neo_evaluasi"
														:options="mNeoEvaluasi"></b-form-checkbox-group>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
				<template v-else>
					<div class="card" v-if="(isParent.row.ap_usia <= 18 && isParent.row.ap_usia != null)">
						<div class="card-header bg-info">
							<h5 class="card-title font-weight-semibold">Kajian Humpty Dumpty</h5>
						</div>
						<div class="card-body">
							<div class="form-group">
								<table class="table table-bordered table-hover pain-scale-table">
									<thead>
										<tr>
											<th>Pengkajian</th>
											<th>Nilai 1</th>
											<th>Nilai 2</th>
											<th>Nilai 3</th>
											<th>Nilai 4</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<th class="table-warning text-center">Umur</th>
											<td v-for="(v,k) in (Config.mr.hdUmur||[])" :key="k">
												<b-form-radio v-model="rowData.arankprjd_humpty_umur" name="arankprjd_humpty_umur"
													:value="v.value">{{v.text}}</b-form-radio>
											</td>
										</tr>
										<tr>
											<th class="table-warning text-center">Jenis Kelamin</th>
											<td v-for="(v,k) in (Config.mr.hdJK||[])" :key="k">
												<b-form-radio v-model="rowData.arankprjd_humpty_jenis_kelamin"
													name="arankprjd_humpty_jenis_kelamin" :value="v.value">{{v.text}}</b-form-radio>
											</td>
										</tr>
										<tr>
											<th class="table-warning text-center">Diagnosis</th>
											<td v-for="(v,k) in (Config.mr.hdDiagnosis||[])" :key="k">
												<b-form-radio v-model="rowData.arankprjd_humpty_diagnosis" name="arankprjd_humpty_diagnosis"
													:value="v.value">{{v.text}}</b-form-radio>
											</td>
										</tr>
										<tr>
											<th class="table-warning text-center">Gangguan Kognitif</th>
											<td v-for="(v,k) in (Config.mr.hdGangguanKognitif||[])" :key="k">
												<b-form-radio v-model="rowData.arankprjd_humpty_gangguan_kognitif"
													name="arankprjd_humpty_gangguan_kognitif" :value="v.value">{{v.text}}</b-form-radio>
											</td>
										</tr>
										<tr>
											<th class="table-warning text-center">Faktor Lingkungan</th>
											<td v-for="(v,k) in (Config.mr.hdFaktorLingkungan||[])" :key="k">
												<b-form-radio v-model="rowData.arankprjd_humpty_faktor_lingkungan"
													name="arankprjd_humpty_faktor_lingkungan" :value="v.value">{{v.text}}</b-form-radio>
											</td>
										</tr>
										<tr>
											<th class="table-warning text-center">Respon Pembedahan</th>
											<td v-for="(v,k) in (Config.mr.hdPembedahan||[])" :key="k">
												<b-form-radio v-model="rowData.arankprjd_humpty_respon_pembedahan"
													name="arankprjd_humpty_respon_pembedahan" :value="v.value">{{v.text}}</b-form-radio>
											</td>
										</tr>
										<tr>
											<th class="table-warning text-center">Penggunaan Obat</th>
											<td v-for="(v,k) in (Config.mr.hdPenggunaanObat||[])" :key="k">
												<b-form-radio v-model="rowData.arankprjd_humpty_respon_penggunaan_obat"
													name="arankprjd_humpty_respon_penggunaan_obat" :value="v.value">{{v.text}}</b-form-radio>
											</td>
										</tr>
									</tbody>
									<tfoot>
										<tr class="table-info">
											<td colspan="5" class="text-info-700">
												<span class="text-uppercase">Tingkat Risiko Humpty Dumpty: {{hdTotal}}</span>
												<span v-if="hdTotal < 7" class="border-left ml-2 pl-2 font-weight-semibold">Tidak
													Beresiko</span>
												<span v-else-if="hdTotal >= 7 && hdTotal <= 11"
													class="border-left ml-2 pl-2 font-weight-semibold">Risiko Rendah</span>
												<span v-else-if="hdTotal >= 12" class="border-left ml-2 pl-2 font-weight-semibold">Risiko
													Tinggi</span>
											</td>
											<!-- Penilaian Tingkat Risiko Humpty Dumpty
                        7 - 11 = Risiko Rendah
                        >= 12 = Risiko Tinggi
                        -->
										</tr>
									</tfoot>
								</table>
								<VValidate name="Humpty Dumpty Umur" v-model="rowData.arankprjd_humpty_umur"
									:rules="toValidate(mrValidation.arankprjd_humpty_umur)" class="d-block" />


								<VValidate name="Humpty Dumpty Jenis Kelamin" v-model="rowData.arankprjd_humpty_jenis_kelamin"
									:rules="toValidate(mrValidation.arankprjd_humpty_jenis_kelamin)" class="d-block" />

								<VValidate name="Humpty Dumpty Diagnosis" v-model="rowData.arankprjd_humpty_diagnosis"
									:rules="toValidate(mrValidation.arankprjd_humpty_diagnosis)" class="d-block" />

								<VValidate name="Humpty Dumpty Gangguan Kognitif" v-model="rowData.arankprjd_humpty_gangguan_kognitif"
									:rules="toValidate(mrValidation.arankprjd_humpty_gangguan_kognitif)" class="d-block" />

								<VValidate name="Humpty Dumpty Faktor Lingkungan" v-model="rowData.arankprjd_humpty_faktor_lingkungan"
									:rules="toValidate(mrValidation.arankprjd_humpty_faktor_lingkungan)" class="d-block" />

								<VValidate name="Humpty Dumpty Respon Pembedahan" v-model="rowData.arankprjd_humpty_respon_pembedahan"
									:rules="toValidate(mrValidation.arankprjd_humpty_respon_pembedahan)" class="d-block" />

							</div>
						</div>
					</div>

					<div class="card" v-else-if="(isParent.row.ap_usia > 18 && isParent.row.ap_usia <= 60)">
						<div class="card-header bg-info">
							<h5 class="card-title font-weight-semibold">Kajian Morse Fall Scale</h5>
						</div>
						<div class="card-body">
							<div class="form-group">
								<table class="table table-bordered table-hover pain-scale-table">
									<thead>
										<tr>
											<th>Pengkajian</th>
											<th>Nilai 1</th>
											<th>Nilai 2</th>
											<th>Nilai 3</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<th class="table-warning text-center">Riwayat Jatuh dalam 3 Bulan Terakhir</th>
											<td v-for="(v,k) in (Config.mr.snRiwayatJatuh||[])" :key="k">
												<b-form-radio v-model="rowData.arankprjd_morshe_riwayat_jatuh"
													name="arankprjd_morshe_riwayat_jatuh" :value="v.value">{{v.text}}</b-form-radio>
											</td>
										</tr>
										<tr>
											<th class="table-warning text-center">Diagnosis Medis Sekunder > 1</th>
											<td v-for="(v,k) in (Config.mr.snDiagnosa||[])" :key="k">
												<b-form-radio v-model="rowData.arankprjd_morshe_diagnosa" name="arankprjd_morshe_diagnosa"
													:value="v.value">{{v.text}}</b-form-radio>
											</td>
										</tr>
										<tr>
											<th class="table-warning text-center">Alat Bantu Jalan</th>
											<td v-for="(v,k) in (Config.mr.snAlatBantuJalan||[])" :key="k">
												<b-form-radio v-model="rowData.arankprjd_morshe_alat_bantu" name="arankprjd_morshe_alat_bantu"
													:value="v.value">{{v.text}}</b-form-radio>
											</td>
										</tr>
										<tr>
											<th class="table-warning text-center">Pakai Infus</th>
											<td v-for="(v,k) in (Config.mr.snPakaiInfus||[])" :key="k">
												<b-form-radio v-model="rowData.arankprjd_morshe_inpus" name="arankprjd_morshe_inpus"
													:value="v.value">{{v.text}}</b-form-radio>
											</td>
										</tr>
										<tr>
											<th class="table-warning text-center">Cara Berjalan / Berpindah</th>
											<td v-for="(v,k) in (Config.mr.snCaraJalan||[])" :key="k">
												<b-form-radio v-model="rowData.arankprjd_morshe_cara_jalan" name="arankprjd_morshe_cara_jalan"
													:value="v.value">{{v.text}}</b-form-radio>
											</td>
										</tr>
										<tr>
											<th class="table-warning text-center">Status Mental</th>
											<td v-for="(v,k) in (Config.mr.snStatusMental||[])" :key="k">
												<b-form-radio v-model="rowData.arankprjd_morshe_status_mental"
													name="arankprjd_morshe_status_mental" :value="v.value">{{v.text}}</b-form-radio>
											</td>
										</tr>
									</tbody>
									<tfoot>
										<tr class="table-info">
											<td colspan="5" class="text-info-700">
												<span class="text-uppercase">Tingkat Risiko Morse Fall Scale: {{morsheTotal}}</span>
												<span v-if="morsheTotal < 24" class="border-left ml-2 pl-2 font-weight-semibold">Risiko
													Rendah</span>
												<span v-else-if="morsheTotal >= 25 && morsheTotal <= 44"
													class="border-left ml-2 pl-2 font-weight-semibold">Risiko Sedang</span>
												<span v-else-if="morsheTotal > 45" class="border-left ml-2 pl-2 font-weight-semibold">Risiko
													Tinggi</span>

											</td>
											<!-- Penilaian Tingkat Risiko Humpty Dumpty
                        7 - 11 = Risiko Rendah
                        >= 12 = Risiko Tinggi
                        -->
										</tr>
									</tfoot>
								</table>

								<VValidate name="Morshe Riwayat Jatuh" v-model="rowData.arankprjd_morshe_riwayat_jatuh"
									:rules="toValidate(mrValidation.arankprjd_morshe_riwayat_jatuh)" class="d-block" />


								<VValidate name="Morshe Diagnosa" v-model="rowData.arankprjd_morshe_diagnosa"
									:rules="toValidate(mrValidation.arankprjd_morshe_diagnosa)" class="d-block" />

								<VValidate name="Morshe Alat Bantu" v-model="rowData.arankprjd_morshe_alat_bantu"
									:rules="toValidate(mrValidation.arankprjd_morshe_alat_bantu)" class="d-block" />

								<VValidate name="Morshe Inpus" v-model="rowData.arankprjd_morshe_inpus"
									:rules="toValidate(mrValidation.arankprjd_morshe_inpus)" class="d-block" />

								<VValidate name="Morshe Cara Jalan" v-model="rowData.arankprjd_morshe_cara_jalan"
									:rules="toValidate(mrValidation.arankprjd_morshe_cara_jalan)" class="d-block" />

								<VValidate name="Morshe Status Mental" v-model="rowData.arankprjd_morshe_status_mental"
									:rules="toValidate(mrValidation.arankprjd_morshe_status_mental)" class="d-block" />


								<div class="row mt-2">
									<div class="col-md-6">
										<table class="table table-bordered table-striped table-hover table-sm patient-table">
											<thead>
												<tr>
													<th>HASIL</th>
													<th>TINDAKAN</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>Tidak Berisiko</td>
													<td>Perawatan yang Baik</td>
												</tr>
												<tr>
													<td>Risiko Rendah</td>
													<td>Lakukan intervensi jatuh standar</td>
												</tr>
												<tr>
													<td>Risiko Tinggi</td>
													<td>Lakukan intervensi jatuh risiko tinggi</td>
												</tr>
											</tbody>
										</table>
									</div>
									<!-- <div class="col-md-6">
                        <div class="form-group">
                        <label for="">Catatan Tindakan </label>
                        <textarea name="" id="" rows="4" class="form-control"></textarea>
                        </div>
                    </div> -->
								</div>
							</div>
						</div>
					</div>

					<div class="card" v-else-if="isParent.row.ap_usia > 60">
						<div class="card-header bg-info">
							<h5 class="card-title font-weight-semibold">Kajian Skala Ontario Modified Stratify - Sydney Scoring</h5>
						</div>
						<div class="card-body">
							<div class="form-group">
								<label for="kidPainScale">Skala Risiko</label>
								<table class="table table-bordered">
									<tbody>
										<tr class="table-warning">
											<th colspan="2"><span class="font-weight-semibold">Riwayat Jatuh</span></th>
										</tr>
										<tr>
											<td>Apakah pasien datang ke rumah sakit karena jatuh?</td>
											<td>
												<b-form-radio-group :options="isParent.Config.mr.yesNoOptV2"
													v-model="rowData.arankprjd_riwayat_jatuh_is_jatuh" />
												<VValidate name="Pasien Jatuh" v-model="rowData.arankprjd_riwayat_jatuh_is_jatuh"
													:rules="toValidate(mrValidation.arankprjd_riwayat_jatuh_is_jatuh)" />
											</td>
										</tr>
										<tr v-if="rowData.arankprjd_riwayat_jatuh_is_jatuh == 'N'">
											<td>Jika Tidak, apakah pasien mengalami jatuh dalam 2 bulan terakhir?</td>
											<td>
												<b-form-radio-group :options="isParent.Config.mr.yesNoOptV2"
													v-model="rowData.arankprjd_riwayat_jatuh_is_jatuh2bulan" />
												<VValidate name="Pasien Jatuh Dalam 2 Bulan"
													v-model="rowData.arankprjd_riwayat_jatuh_is_jatuh2bulan"
													:rules="toValidate(mrValidation.arankprjd_riwayat_jatuh_is_jatuh2bulan)" />
											</td>
										</tr>
									</tbody>
									<tbody>
										<tr class="table-warning">
											<th colspan="2"><span class="font-weight-semibold">Status Mental</span></th>
										</tr>
										<tr>
											<td>Apakah pasien delirium? (tidak membuat keputusan, pola pikir tidak terorganisir, gangguan daya
												ingat)</td>
											<td>
												<b-form-radio-group :options="isParent.Config.mr.yesNoOptV2"
													v-model="rowData.arankprjd_status_mental_dellirium" />
												<VValidate name="Mental Dellirium" v-model="rowData.arankprjd_status_mental_dellirium"
													:rules="toValidate(mrValidation.arankprjd_status_mental_dellirium)" />
											</td>
										</tr>
										<tr>
											<td>Apakah pasien disorientasi? (salah menyebutkan waktu, tempat atau orang)</td>
											<td>
												<b-form-radio-group :options="isParent.Config.mr.yesNoOptV2"
													v-model="rowData.arankprjd_status_mental_disorientasi" />
												<VValidate name="Mental Disorientasi" v-model="rowData.arankprjd_status_mental_disorientasi"
													:rules="toValidate(mrValidation.arankprjd_status_mental_disorientasi)" />
											</td>
										</tr>
										<tr>
											<td>Apakah pasien mengalami agitasi? (ketakutan, gelisah dan cemas)</td>
											<td>

												<b-form-radio-group :options="isParent.Config.mr.yesNoOptV2"
													v-model="rowData.arankprjd_status_mental_agitasi" />
												<VValidate name="Mental Agitasi" v-model="rowData.arankprjd_status_mental_agitasi"
													:rules="toValidate(mrValidation.arankprjd_status_mental_agitasi)" />
											</td>
										</tr>
									</tbody>
									<tbody>
										<tr class="table-warning">
											<th colspan="2"><span class="font-weight-semibold">Penglihatan</span></th>
										</tr>
										<tr>
											<td>Apakah memakai kacamata?</td>
											<td>
												<b-form-radio-group :options="isParent.Config.mr.yesNoOptV2"
													v-model="rowData.arankprjd_penglihatan_kacamata" />
												<VValidate name="Penglihatan Kacamata" v-model="rowData.arankprjd_penglihatan_kacamata"
													:rules="toValidate(mrValidation.arankprjd_penglihatan_kacamata)" />
											</td>
										</tr>
										<tr>
											<td>Apakah pasien mengeluh penglihatan buram?</td>
											<td>
												<b-form-radio-group :options="isParent.Config.mr.yesNoOptV2"
													v-model="rowData.arankprjd_penglihatan_buram" />
												<VValidate name="Penglihatan Buram" v-model="rowData.arankprjd_penglihatan_buram"
													:rules="toValidate(mrValidation.arankprjd_penglihatan_buram)" />
											</td>
										</tr>
										<tr>
											<td>Apakah pasien mempunyai glaucoma, katarak atau degenerasi makula?</td>
											<td>
												<b-form-radio-group :options="isParent.Config.mr.yesNoOptV2"
													v-model="rowData.arankprjd_penglihatan_glaucoma" />
												<VValidate name="Penglihatan Glaucomma" v-model="rowData.arankprjd_penglihatan_glaucoma"
													:rules="toValidate(mrValidation.arankprjd_penglihatan_glaucoma)" />
											</td>
										</tr>
									</tbody>
									<tbody>
										<tr class="table-warning">
											<th colspan="2"><span class="font-weight-semibold">Kebiasaan</span></th>
										</tr>
										<tr>
											<td>Apakah terdapat perubahan perilaku berkemih? (frekuensi, urgensi, inkontinensia, nokturia)
											</td>
											<td>
												<b-form-radio-group :options="isParent.Config.mr.yesNoOptV2"
													v-model="rowData.arankprjd_kebiasaan" />
												<VValidate name="Perilaku Berkemih" v-model="rowData.arankprjd_kebiasaan"
													:rules="toValidate(mrValidation.arankprjd_kebiasaan)" />
											</td>
										</tr>


									</tbody>
									<tbody>
										<tr class="table-warning">
											<th colspan="2"><span class="font-weight-semibold">Transfer (dari tempat tidur ke kursi dan
													kembali ke tempat tidur)</span></th>
										</tr>
										<tr v-for="(v,k) in (Config.mr.syTransfer||[])" :key="k">
											<td colspan="2">
												<b-form-radio v-model="rowData.arankprjd_transfer" name="arankprjd_transfer" :value="v.value">
													{{v.text}}</b-form-radio>
											</td>
										</tr>
									</tbody>
									<tbody>
										<tr class="table-warning">
											<th colspan="2"><span class="font-weight-semibold">Mobilitas</span></th>
										</tr>
										<tr v-for="(v,k) in (Config.mr.syMobilitas||[])" :key="k">
											<td colspan="2">
												<b-form-radio v-model="rowData.arankprjd_mobilitas" name="arankprjd_mobilitas" :value="v.value">
													{{v.text}}</b-form-radio>
											</td>
										</tr>
									</tbody>
									<tfoot>
										<tr class="table-info">
											<td colspan="5" class="text-info-700">
												<span class="text-uppercase">Tingkat Risiko Ontario Modified Stratify - Sydney Scoring:
													{{getTotalSydney}}</span>

												<span v-if="getTotalSydney < 6" class="border-left ml-2 pl-2 font-weight-semibold">Risiko
													Rendah</span>
												<span v-else-if="getTotalSydney >= 6 && getTotalSydney < 17"
													class="border-left ml-2 pl-2 font-weight-semibold">Risiko Sedang</span>
												<span v-else class="border-left ml-2 pl-2 font-weight-semibold">Risiko Tinggi</span>

											</td>
											<!-- Penilaian Tingkat Risiko Ontario Modified Stratify - Sydney Scoring
                        0 - 5 = Risiko Rendah
                        6 - 16 = Risiko Sedang
                        17 - 30 = Risiko Tinggi
                        -->
										</tr>
									</tfoot>
								</table>

								<VValidate name="Transfer" v-model="rowData.arankprjd_transfer"
									:rules="toValidate(mrValidation.arankprjd_transfer)" class="d-block" />

								<VValidate name="Mobilitas" v-model="rowData.arankprjd_mobilitas"
									:rules="toValidate(mrValidation.arankprjd_mobilitas)" class="d-block" />



							</div>
						</div>
					</div>
				</template>

				<div class="card-body mt-2">
					<div class="row">
						<div class="col-md-12">
							<table class="table table-bordered table-striped table-hover table-sm patient-table">
								<thead>
									<tr>
										<th>HASIL</th>
										<th>TINDAKAN</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<span v-if="resikoIntervensiType == 'S'">Intervensi Resiko Jatuh Standar</span>
											<span v-else-if="resikoIntervensiType == 'T'">Intervensi Resiko Jatuh Tinggi</span>
											<span v-else>Tidak Beresiko</span>

										</td>
										<td>
											<b-form-checkbox-group v-if="resikoIntervensiType == 'S'" name="intervensi-resiko"
												class="checkbox-block" v-model="rowData.arankprjd_data_intervensi"
												:options="(Config.mr.ranapResikoJatuhSedang||[])"></b-form-checkbox-group>
											<b-form-checkbox-group v-else-if="resikoIntervensiType == 'T'" name="intervensi-resiko"
												class="checkbox-block" v-model="rowData.arankprjd_data_intervensi"
												:options="(Config.mr.ranapResikoJatuhTinggi||[])"></b-form-checkbox-group>
											<b-form-checkbox-group v-else name="intervensi-resiko" class="checkbox-block"
												v-model="rowData.arankprjd_data_intervensi" :options="(Config.mr.ranapResikoJatuhRendah||[])">
											</b-form-checkbox-group>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>

				<div class="card">
					<div class="card-header bg-info">
						<h5 class="card-title font-weight-semibold">Checklist Pengaman</h5>
					</div>
					<div class="card-body">
						<div>
						
							<b-form-checkbox @change="checkAllPengaman($event)" v-model="rowData.arankprjd_pengaman_check_all" value="Y"
							unchecked-value="N" class="form-check-input-styled p-0 ml-4">
									Pilih Semua Item
							</b-form-checkbox>

							<table class="table table-bordered table-sm">
								<tbody>
									<tr class="table-warning">
										<th><span class="font-weight-semibold">TEMPAT TIDUR</span></th>
										<th class="font-weight-semibold"><span class="font-weight-semibold">CHECKLIST</span></th>
									</tr>
									<tr class="table-secondary ">
										<td colspan="2">
											Pegangan sisi tempat tidur
										</td>
									</tr>

									<tr>
										<td>
											Mudah dinaikkan dan diturunkan
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_bed_cek_naik_turun" value="Y" unchecked-value="N"
													class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>

									<tr>
										<td>
											Terkunci dengan aman saat dinaikkan
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_bed_cek_aman_saat_naik" value="Y"
													unchecked-value="N" class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>

									<tr>
										<td>
											Hanya digunakan untuk mobilitas
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_bed_cek_saat_mobilitas" value="Y"
													unchecked-value="N" class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>

									<tr class="table-secondary ">
										<td colspan="2">
											Roda
										</td>
									</tr>
									<tr>
										<td>
											Mudah berputar/diarahkan, tidak melekat
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_bed_cek_roda" value="Y" unchecked-value="N"
													class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>

									<tr class="table-secondary ">
										<td colspan="2">
											Rem
										</td>
									</tr>

									<tr>
										<td>
											Mengamankan tempat tidur saat dioperasikan
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_bed_cek_rem" value="Y" unchecked-value="N"
													class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>

									<tr class="table-secondary ">
										<td colspan="2">
											Mekanik
										</td>
									</tr>

									<tr>
										<td>
											Pengaturan ketinggian tempat tidur mudah dilakukan
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_bed_cek_mekanik" value="Y" unchecked-value="N"
													class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>

									<tr class="table-secondary ">
										<td colspan="2">
											Meja samping tempat tidur
										</td>
									</tr>

									<tr>
										<td>
											Roda terkunci dengan baik
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_bed_cek_meja_terkunci" value="Y" unchecked-value="N"
													class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>

									<tr>
										<td>
											Letaknya disamping tempat tidur, menempel didinding
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_bed_cek_meja_didinding" value="Y"
													unchecked-value="N" class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>
								</tbody>
								<tbody>
									<tr class="table-warning">
										<th><span class="font-weight-semibold">TIANG INFUS</span></th>
										<th class="font-weight-semibold"><span class="font-weight-semibold">CHECKLIST</span></th>
									</tr>
									<tr class="table-secondary ">
										<td colspan="2">
											Tiang
										</td>
									</tr>

									<tr>
										<td>
											Mudah dinaikkan dan diturunkan

										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_tiang_cek_naik_turun" value="Y" unchecked-value="N"
													class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>

									<tr>
										<td>
											Stabil, tidak mudah goyang
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_tiang_cek_stabil" value="Y" unchecked-value="N"
													class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>

									<tr class="table-secondary ">
										<td colspan="2">
											Roda
										</td>
									</tr>

									<tr>
										<td>
											Mudah berputar/diarahkan, tidak melekat
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_tiang_cek_berputar" value="Y" unchecked-value="N"
													class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>


								</tbody>
								<tbody>
									<tr class="table-warning">
										<th><span class="font-weight-semibold">TUMPUAN KAKI</span></th>
										<th class="font-weight-semibold"><span class="font-weight-semibold">CHECKLIST</span></th>
									</tr>
									<tr class="table-secondary ">
										<td colspan="2">
											Kaki kursi
										</td>
									</tr>

									<tr>
										<td>
											Proteksi karet anti-selip dikesemua kaki
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_tumpuan_proteksi" value="Y" unchecked-value="N"
													class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>

									<tr>
										<td>
											Stabil, tidak goyang
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_tumpuan_stabil" value="Y" unchecked-value="N"
													class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>

									<tr class="table-secondary ">
										<td colspan="2">
											Bagian atas kursi
										</td>
									</tr>

									<tr>
										<td>
											Permukaan tidak licin
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_tumpuan_tidak_licin" value="Y" unchecked-value="N"
													class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>
								</tbody>

								<tbody>
									<tr class="table-warning">
										<th><span class="font-weight-semibold">BEL PANGGILAN/ PENCAHAYAAN</span></th>
										<th class="font-weight-semibold"><span class="font-weight-semibold">CHECKLIST</span></th>
									</tr>
									<tr class="table-secondary ">
										<td colspan="2">
											Operasional
										</td>
									</tr>

									<tr>
										<td>
											Lampu diluar kamar
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_bel_lampu_kamar" value="Y" unchecked-value="N"
													class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>

									<tr>
										<td>
											Alarm berbunyi di counter
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_bel_alarm" value="Y" unchecked-value="N"
													class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>

									<tr>
										<td>
											Nomor kamar muncul di monitor
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_bel_nomor_kamar" value="Y" unchecked-value="N"
													class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>

									<tr>
										<td>
											Sinyal panel kamar
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_bel_sinyal_kamar" value="Y" unchecked-value="N"
													class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>

									<tr class="table-secondary ">
										<td colspan="2">
											Akses
										</td>
									</tr>

									<tr>
										<td>
											Mudah diraih saat di kamar mandi
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_bel_mudah_diraih" value="Y" unchecked-value="N"
													class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>

									<tr>
										<td>
											Dalam jangkauan saat pasien di tempat tidur
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_bel_tempat_tidur" value="Y" unchecked-value="N"
													class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>
								</tbody>

								<tbody>
									<tr class="table-warning">
										<th><span class="font-weight-semibold">WALKER/ CANE</span></th>
										<th class="font-weight-semibold"><span class="font-weight-semibold">CHECKLIST</span></th>
									</tr>
									<tr class="table-secondary ">
										<td colspan="2">
											Keamanan
										</td>
									</tr>

									<tr>
										<td>
											Ujung karet pada alat berfungsi dengan baik
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_walker_ujung_karet" value="Y" unchecked-value="N"
													class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>

									<tr>
										<td>
											Stabil
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_walker_stabil" value="Y" unchecked-value="N"
													class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>
								</tbody>

								<tbody>
									<tr class="table-warning">
										<th><span class="font-weight-semibold">TOILET BERJALAN</span></th>
										<th class="font-weight-semibold"><span class="font-weight-semibold">CHECKLIST</span></th>
									</tr>
									<tr class="table-secondary ">
										<td colspan="2">
											Roda
										</td>
									</tr>

									<tr>
										<td>
											Mudah berputar/diarahkan, tidak melekat
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_toilet_berputar" value="Y" unchecked-value="N"
													class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>

									<tr>
										<td>
											Stabil saat pasien duduk diatasnya
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_toilet_stabil" value="Y" unchecked-value="N"
													class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>


									<tr class="table-secondary ">
										<td colspan="2">
											Rem
										</td>
									</tr>

									<tr>
										<td>
											Mengamankan tempat tidur saat dioperasikan
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_rem" value="Y" unchecked-value="N"
													class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>

								</tbody>

								<tbody>
									<tr class="table-warning">
										<th><span class="font-weight-semibold">KURSI BERODA (MOBILITY CHAIR)</span></th>
										<th class="font-weight-semibold"><span class="font-weight-semibold">CHECKLIST</span></th>
									</tr>
									<tr class="table-secondary ">
										<td colspan="2">
											Kursi
										</td>
									</tr>

									<tr>
										<td>
											Tingginya disesuaikan dengan pasien, untuk meminimalisir terjatuh/ terjungkal
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_kursi_tinggi_sesuai" value="Y" unchecked-value="N"
													class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>

									<tr class="table-secondary ">
										<td colspan="2">
											Roda
										</td>
									</tr>

									<tr>
										<td>
											Mudah berputar/diarahkan, tidak melekat
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_kursi_roda_berputar" value="Y" unchecked-value="N"
													class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>
									<tr class="table-secondary ">
										<td colspan="2">
											Rem
										</td>
									</tr>

									<tr>
										<td>
											Dioperasikan saat kursi dalam posisi diam
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_kursi_rem_dioperasikan" value="Y"
													unchecked-value="N" class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>

									<tr>
										<td>
											Mengamankan toilet saat dioperasikan
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_kursi_rem_aman" value="Y" unchecked-value="N"
													class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>

									<tr>
										<td>
											Pengaman kursi
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_kursi_rem_pengaman" value="Y" unchecked-value="N"
													class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>

									<tr class="table-secondary ">
										<td colspan="2">
											Tumpuan kaki
										</td>
									</tr>

									<tr>
										<td>
											Dapat dilipat
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_kursi_kaki_dapat_dilipat" value="Y"
													unchecked-value="N" class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>

									<tr class="table-secondary ">
										<td colspan="2">
											Posisi
										</td>
									</tr>

									<tr>
										<td>
											Diposisikan dengan derajat kemiringan yang sesuai untuk mencegah terjungkal kedepan/
											merosot
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_kursi_kaki_derajat_kemiringan" value="Y"
													unchecked-value="N" class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>

									<tr class="table-secondary ">
										<td colspan="2">
											Nampan
										</td>
									</tr>

									<tr>
										<td>
											Dalam posisi aman
										</td>
										<td>
											<div class="custom-control custom-control-inline custom-checkbox">
												<b-form-checkbox v-model="rowData.arankprjd_kursi_kaki_posisi_aman" value="Y"
													unchecked-value="N" class="form-check-input-styled p-0">
												</b-form-checkbox>
											</div>
										</td>
									</tr>

								</tbody>

							</table>
						</div>
					</div>
				</div>

				<div class="card ">
					<div class="card-header bg-info">
						<h5 class="card-title font-weight-semibold">Jadwal Monitoring</h5>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-md-12">
								<label for="">Jadwal Monitoring <span class="text-danger">*</span></label>
								<b-form-radio-group @input="changeMonitor($event)" :options="Config.mr.lanjutanMonitoring"
									v-model="rowData.arankprj_is_next_monitoring" />
								<VValidate :name="'Jadwal Monitoring'" v-model="rowData.arankprj_is_next_monitoring"
									:rules="{required: 1}" />

							</div>
						</div>

						<div v-if="isMonitoring && rowData.arankprj_is_next_monitoring == 'Y'">
							<table class="table table-bordered table-sm table-striped mt-2">
								<thead>
									<tr>
										<td>Jadwal</td>
										<td>Tanggal dan Jam</td>
										<td>Aksi</td>
									</tr>
								</thead>

								<tbody>
									<tr v-for="(v,k) in (rowData.arankprjd_jadwal_monitoring||[])" :key="k+'jm'">
										<td>
											Ke-{{k+1}}
										</td>
										<td>
											<div class="input-group mb-3">
												<datepicker :disabledDates="disabledDates" input-class="form-control transparent h-38"
													placeholder="Tanggal" class="my-datepicker" calendar-class="my-datepicker_calendar"
													v-model="rowData.arankprjd_jadwal_monitoring[k]['date']">
												</datepicker>
												<vue-timepicker manual-input format="HH:mm" placeholder="Jam" input-class="form-control h-38"
													v-model="rowData.arankprjd_jadwal_monitoring[k]['jam']">
												</vue-timepicker>
												<div class="input-group-append calendar-group">
													<span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
												</div>
											</div>
											<VValidate :name="'Tanggal'+(k+1)" v-model="rowData.arankprjd_jadwal_monitoring[k]['date']"
												:rules="{required : 1}" class="d-block" />
											<VValidate :name="'Jam'+(k+1)" v-model="rowData.arankprjd_jadwal_monitoring[k]['jam']"
												:rules="{required: 1}" />
										</td>

										<td class="input-cell text-center">
											<a href="javascript:;" v-if="(rowData.arankprjd_jadwal_monitoring||[]).length > 1"
												@click="(rowData.arankprjd_jadwal_monitoring||[]).splice(k,1)" data-popup="tooltip"
												title="Hapus" class="btn btn-sm btn-icon  text-danger-800 alpha-danger"><i
													class="icon-trash"></i></a>
										</td>
									</tr>
								</tbody>
								<tfoot v-if="rowData.arankprj_status != 'DONE'">
									<tr>
										<td colspan="5" class="text-info-700 text-center">
											<a href="javascript:;" @click="addJadwal()" class="btn btn-sm alpha-info border-info"><i
													class="icon-plus2 mr-1"></i>Tambah Jadwal</a>
										</td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>

				<div class="card-footer">
					<div class="text-right">
						<button type="button" @click="back()" class="btn btn-light mr-2">Back</button>
						<button type="button" class="btn btn-info mr-2" @click="doSave()">Simpan Sementara <i
								class="icon-paperplane mr-2"></i></button>
						<button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane mr-2"></i></button>
					</div>
				</div>
			</b-form>
		</validation-observer>
	</div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default{
  extends: GlobalVue,
  data() {
    return {
      rowData: {},
      isMonitoring: false,
      mNeoIntervensi: [],
      mNeoEdukasi: [],
      mNeoSasaran: [],
      mNeoEvaluasi: [],
    }
  },
  components: {
    Datepicker,VueTimepicker
  },
  computed: {
    isParent(){
      return this.$parent
    },
    resikoIntervensiType(){
      if(this.isParent.row.ap_usia <= 18){
          // humpty dumpty
          if(this.hdTotal < 7){
              return 'TB'
          }else if(this.hdTotal >= 7 && this.hdTotal <= 11){
              return 'S'
          }else{
              return 'T'
          }
      }else if(this.isParent.row.ap_usia > 18 && this.isParent.row.ap_usia <= 60){
          // morshe                
          if(!this.morsheTotal){
              return 'TB'
          }else if(this.morsheTotal >= 1 && this.morsheTotal < 24){
              return 'S'
          }else if(this.morsheTotal >= 25 && this.morsheTotal <= 44){
              return 'S'
          }else{
              return 'T'
          }
      }else if(this.isParent.row.ap_usia > 60){
          if(!this.getTotalSydney){
              return 'TB'
          }else if(this.getTotalSydney >= 1 && this.getTotalSydney < 6){
              return 'S'
          }else if(this.getTotalSydney >= 6 && this.getTotalSydney < 17){
              return 'S'
          }else{
              return 'T'
          }
      }else{
          return 'TB'
      } 
    },
    
    edTotal(){
        return (this.rowData.arankprjd_ed_umur||0) + 
        (this.rowData.arankprjd_ed_status_mental||0) + 
        ((this.rowData.arankprjd_ed_pola_pikir == 11 ? (11+1): this.rowData.arankprjd_ed_pola_pikir)||0) + 
        (this.rowData.arankprjd_ed_pengobatan||0) + 
        (this.rowData.arankprjd_ed_diagnosis||0) + 
        (this.rowData.arankprjd_ed_ambulasi||0) + 
        (this.rowData.arankprjd_ed_nutrisi||0) + 
        (this.rowData.arankprjd_ed_riwayat_jatuh||0) 
    },

    hdTotal(){
        return (this.rowData.arankprjd_humpty_umur||0) + 
        (this.rowData.arankprjd_humpty_jenis_kelamin||0) + 
        (this.rowData.arankprjd_humpty_diagnosis||0) + 
        (this.rowData.arankprjd_humpty_gangguan_kognitif||0) + 
        (this.rowData.arankprjd_humpty_faktor_lingkungan||0) + 
        (this.rowData.arankprjd_humpty_respon_pembedahan||0) +
        (this.rowData.arankprjd_humpty_respon_penggunaan_obat||0) 
    },

    morsheTotal(){
        return (this.rowData.arankprjd_morshe_riwayat_jatuh||0) + 
        (this.rowData.arankprjd_morshe_diagnosa||0) + 
        (this.rowData.arankprjd_morshe_alat_bantu||0) + 
        (this.rowData.arankprjd_morshe_inpus||0) + 
        (this.rowData.arankprjd_morshe_cara_jalan||0) + 
        (this.rowData.arankprjd_morshe_status_mental||0) 
    },

    
    getTotalSydney(){
      let total = 0
      
      if(this.rowData.arankprjd_riwayat_jatuh_is_jatuh == "Y" || this.rowData.arankprjd_riwayat_jatuh_is_jatuh2bulan == "Y"){
          total = total + 6
      }

      if(this.rowData.arankprjd_status_mental_dellirium == "Y" || this.rowData.arankprjd_status_mental_disorientasi == "Y" ||
      this.rowData.arankprjd_status_mental_agitasi == "Y" ){
          total = total + 14
      }
      
      if(this.rowData.arankprjd_penglihatan_kacamata == "Y" || this.rowData.arankprjd_penglihatan_buram == "Y" ||
      this.rowData.arankprjd_penglihatan_glaucoma == "Y" ){
          total = total + 1
      }

      if(this.rowData.arankprjd_kebiasaan == "Y"){
          total = total + 2
      }

      if(this.rowData.arankprjd_transfer + this.rowData.arankprjd_mobilitas > 3){
          total = total + 7
      }
      return total  
    },

		isFillAllChecklistTindakan(){
			return this.rowData.arankprjd_bed_cek_naik_turun == "Y" && this.rowData.arankprjd_bed_cek_aman_saat_naik == "Y" && this.rowData.arankprjd_bed_cek_saat_mobilitas == "Y" && this.rowData.arankprjd_bed_cek_aman_saat_naik == "Y" && this.rowData.arankprjd_bed_cek_saat_mobilitas == "Y" && this.rowData.arankprjd_bed_cek_roda == "Y" && this.rowData.arankprjd_bed_cek_rem == "Y" && this.rowData.arankprjd_bed_cek_mekanik == "Y" && this.rowData.arankprjd_bed_cek_meja_terkunci == "Y" && this.rowData.arankprjd_bed_cek_meja_didinding == "Y" && this.rowData.arankprjd_tiang_cek_naik_turun == "Y" && this.rowData.arankprjd_tiang_cek_stabil == "Y" && this.rowData.arankprjd_tiang_cek_berputar == "Y" && this.rowData.arankprjd_tumpuan_proteksi == "Y" && this.rowData.arankprjd_tumpuan_stabil == "Y" && this.rowData.arankprjd_tumpuan_tidak_licin == "Y" && this.rowData.arankprjd_bel_lampu_kamar == "Y" && this.rowData.arankprjd_bel_alarm == "Y" && this.rowData.arankprjd_bel_nomor_kamar == "Y" && this.rowData.arankprjd_bel_sinyal_kamar == "Y" && this.rowData.arankprjd_bel_mudah_diraih == "Y" && this.rowData.arankprjd_bel_tempat_tidur == "Y" && this.rowData.arankprjd_walker_ujung_karet == "Y" && this.rowData.arankprjd_walker_stabil == "Y" && this.rowData.arankprjd_toilet_berputar == "Y" && this.rowData.arankprjd_toilet_stabil == "Y" && this.rowData.arankprjd_rem == "Y" && this.rowData.arankprjd_kursi_tinggi_sesuai == "Y" && this.rowData.arankprjd_kursi_roda_berputar == "Y" && this.rowData.arankprjd_kursi_rem_dioperasikan == "Y" && this.rowData.arankprjd_kursi_rem_aman == "Y" && this.rowData.arankprjd_kursi_rem_pengaman == "Y" && this.rowData.arankprjd_kursi_kaki_dapat_dilipat == "Y" && this.rowData.arankprjd_kursi_kaki_derajat_kemiringan == "Y" && this.rowData.arankprjd_kursi_kaki_posisi_aman == "Y"
		},
  },
  methods: {
		
		checkAllPengaman(e){
			if(e == "Y"){
					this.rowData.arankprjd_bed_cek_naik_turun = "Y"
					this.rowData.arankprjd_bed_cek_aman_saat_naik = "Y"
					this.rowData.arankprjd_bed_cek_saat_mobilitas = "Y"
					this.rowData.arankprjd_bed_cek_roda = "Y"
					this.rowData.arankprjd_bed_cek_rem = "Y"
					this.rowData.arankprjd_bed_cek_mekanik = "Y"
					this.rowData.arankprjd_bed_cek_meja_terkunci = "Y"
					this.rowData.arankprjd_bed_cek_meja_didinding = "Y"
					this.rowData.arankprjd_tiang_cek_naik_turun = "Y"
					this.rowData.arankprjd_tiang_cek_stabil = "Y"
					this.rowData.arankprjd_tiang_cek_berputar = "Y"
					this.rowData.arankprjd_tumpuan_proteksi = "Y"
					this.rowData.arankprjd_tumpuan_stabil = "Y"
					this.rowData.arankprjd_tumpuan_tidak_licin = "Y"
					this.rowData.arankprjd_bel_lampu_kamar = "Y"
					this.rowData.arankprjd_bel_alarm = "Y"
					this.rowData.arankprjd_bel_nomor_kamar = "Y"
					this.rowData.arankprjd_bel_sinyal_kamar = "Y"
					this.rowData.arankprjd_bel_mudah_diraih = "Y"
					this.rowData.arankprjd_bel_tempat_tidur = "Y"
					this.rowData.arankprjd_walker_ujung_karet = "Y"
					this.rowData.arankprjd_walker_stabil = "Y"
					this.rowData.arankprjd_toilet_berputar = "Y"
					this.rowData.arankprjd_toilet_stabil = "Y"
					this.rowData.arankprjd_rem = "Y"
					this.rowData.arankprjd_kursi_tinggi_sesuai = "Y"
					this.rowData.arankprjd_kursi_roda_berputar = "Y"
					this.rowData.arankprjd_kursi_rem_dioperasikan = "Y"
					this.rowData.arankprjd_kursi_rem_aman = "Y"
					this.rowData.arankprjd_kursi_rem_pengaman = "Y"
					this.rowData.arankprjd_kursi_kaki_dapat_dilipat = "Y"
					this.rowData.arankprjd_kursi_kaki_derajat_kemiringan = "Y"
					this.rowData.arankprjd_kursi_kaki_posisi_aman = "Y"
			}else{
					this.rowData.arankprjd_bed_cek_naik_turun = "N"
					this.rowData.arankprjd_bed_cek_aman_saat_naik = "N"
					this.rowData.arankprjd_bed_cek_saat_mobilitas = "N"
					this.rowData.arankprjd_bed_cek_roda = "N"
					this.rowData.arankprjd_bed_cek_rem = "N"
					this.rowData.arankprjd_bed_cek_mekanik = "N"
					this.rowData.arankprjd_bed_cek_meja_terkunci = "N"
					this.rowData.arankprjd_bed_cek_meja_didinding = "N"
					this.rowData.arankprjd_tiang_cek_naik_turun = "N"
					this.rowData.arankprjd_tiang_cek_stabil = "N"
					this.rowData.arankprjd_tiang_cek_berputar = "N"
					this.rowData.arankprjd_tumpuan_proteksi = "N"
					this.rowData.arankprjd_tumpuan_stabil = "N"
					this.rowData.arankprjd_tumpuan_tidak_licin = "N"
					this.rowData.arankprjd_bel_lampu_kamar = "N"
					this.rowData.arankprjd_bel_alarm = "N"
					this.rowData.arankprjd_bel_nomor_kamar = "N"
					this.rowData.arankprjd_bel_sinyal_kamar = "N"
					this.rowData.arankprjd_bel_mudah_diraih = "N"
					this.rowData.arankprjd_bel_tempat_tidur = "N"
					this.rowData.arankprjd_walker_ujung_karet = "N"
					this.rowData.arankprjd_walker_stabil = "N"
					this.rowData.arankprjd_toilet_berputar = "N"
					this.rowData.arankprjd_toilet_stabil = "N"
					this.rowData.arankprjd_rem = "N"
					this.rowData.arankprjd_kursi_tinggi_sesuai = "N"
					this.rowData.arankprjd_kursi_roda_berputar = "N"
					this.rowData.arankprjd_kursi_rem_dioperasikan = "N"
					this.rowData.arankprjd_kursi_rem_aman = "N"
					this.rowData.arankprjd_kursi_rem_pengaman = "N"
					this.rowData.arankprjd_kursi_kaki_dapat_dilipat = "N"
					this.rowData.arankprjd_kursi_kaki_derajat_kemiringan = "N"
					this.rowData.arankprjd_kursi_kaki_posisi_aman = "N"
			}
		},
    changeMonitor(e){
      if(e == "Y" && this.isMonitoring){
        this.addJadwal()
      }else{
        this.rowData.arankprjd_jadwal_monitoring = []      
      }
    },
    back() {
      this.$router.back()
    },
    addJadwal(){
      this.rowData.arankprjd_jadwal_monitoring.push({
        date : null,
        jam : ''
      })
    },
    apiGetForm(params = {}, page = 1){
      if(!this.pageSlug){
          this.$router.push({name : 'Dashboard'}).catch(()=>{})
      }

      if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
      this.data.data = false
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      let url = this.$route.params.kajianNo ? this.modulePage+'/'+this.$route.params.pageSlug+'/'+this.$route.params.kajianNo : this.modulePage+'/'+this.$route.params.pageSlug
      
      Gen.apiRest(
          "/get/"+url, 
          {
          params: Object.assign({page: page}, paramsQuery, params.query||{})
          }
      ).then(res=>{
          this.loadingOverlay = false
          _.forEach(res.data, (v,k)=>{
              this.$set(this, k, v)
          })
          if(!this.isFound){
              this.$router.push({name : 'Dashboard'}).catch(()=>{})
          }
      }).catch(()=>{
          this.loadingOverlay = false
      })

      // master
      if(this.$route.params.kajianNo){
        Gen.apiRest(
          "/get/"+url+'?master=1', 
          {
          params: Object.assign({page: page}, paramsQuery, params.query||{})
          }
        ).then(res=>{
          _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
          })
        })
      }    
    },
    toValidate(val){
      return {...val}
    },        
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
        let index = (master||[]).findIndex(x => x.value == value)
        if (index !== -1) {
            text = master[index]['text']
        }
      }
      return text
    },
    autoSave: _.debounce(function (data) {
      data.type = 'auto-save'
      
      if(!data.isEdit){
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:data}, 
            "POST"
        )
      }
    },1000),

    doSave(){
      let data = this.rowData
      data.arankprj_updated_by = this.user.id
      data.arankprj_updated_date = moment().format("YYYY-MM-DD HH:mm:ss")
      data.type = 'auto-save'

      this.loadingOverlay = true

      Gen.apiRest(
      "/do/" + this.modulePage, {
        data: data
      },
      "POST"
      ).then(res => {
        this.loadingOverlay = false
        
        let resp = res.data
        resp.statusType = 200
        this.$swal({
            title: "Data Berhasil Disimpan",
            icon: "success",
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then(result => {
            this.$router.push({ name: this.modulePage, params: {pageSlug: this.pageSlug}}).catch(()=>{})   
        })
      })
    },

    
    doSubmit(){
      this.$refs['VForm'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                          inv.push(el[i].id)
                          }
                      }
                                  
                      if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                      })
                  }, 500)
              }
          })
        }
        if (success) {
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            text: "Pastikan Jadwal Monitoring yang diiisi telah sesuai",
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.rowData
              data.type = 'submit-data'
              this.loadingOverlay = true
              Gen.apiRest(
              "/do/" + this.modulePage, {
                  data: data
              },
              "POST"
              ).then(res => {
                  this.loadingOverlay = false
                  let resp = res.data
                  resp.statusType = 200
                  this.$swal({
                      title: resp.message,
                      icon: resp.status,
                      confirmButtonText: 'Ok',
                      allowOutsideClick: false,
                      allowEscapeKey: false
                  }).then(result => {
                      this.$router.push({ name: this.modulePage, params: {pageSlug: this.pageSlug}}).catch(()=>{})   
                  }).catch(err => {
                    this.loadingOverlay = false
                    if (err) {
                      err.statusType = err.status
                      err.status = "error"
                      err.title = err.response?.data?.title
                      err.message = err.response?.data?.message
                      err.messageError = err.message
                    }
                    this.doSetAlertForm(err)
                  })
              })
            }
          })
        }
      })
    }
  },
  mounted() {
    this.apiGetForm()
  },
  watch: {
    rowData: {
      handler(v) {
        this.autoSave(v)
      },
      deep: true
    },
    edTotal(v){
      setTimeout(()=>{
          this.rowData.arankprjd_ed_value = v
          this.rowData.arankprj_skor = v
      },250)
    },

    hdTotal(v){
      setTimeout(()=>{
          this.rowData.arankprjd_humpty_value = v
          this.rowData.arankprj_skor = v
      },250)
    },

    morsheTotal(v){
      setTimeout(()=>{
          this.rowData.arankprjd_morshe_value = v
          this.rowData.arankprj_skor = v
      },250)
    },
    
    resikoIntervensiType(v){
        setTimeout(()=>{
            this.rowData.arankprjd_type_intervensi = v
            this.rowData.arankprj_intervensi = v
        },250)
    },
    
    getTotalSydney(v){
      setTimeout(()=>{
          this.rowData.arankprjd_sydney_value = v
          this.rowData.arankprj_skor = v
      },250)    
    },

		isFillAllChecklistTindakan(v){
			if(v){
				this.rowData.arankprjd_pengaman_check_all = "Y"
			}else{
				this.rowData.arankprjd_pengaman_check_all = "N"
			}
		}
  }
}
</script>